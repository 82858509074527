<template>
  <AView
    :value="value"
    :name="service.name"
    :active="service.id === activeServiceId"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <v-card class="pa-2 transparent elevation-0">
        <ServiceSectionFormPlansVue
          ref="form"
          v-model="service.plans"
          :permissions="value.permissions"
          :payload="{
            service,
            from: value,
          }"
          @onPlanCreate="onPlanCreate"
          @onPlanRemove="onPlanRemove"
          @onPlanPackageItemCreate="onPlanPackageItemCreate"
          @onPlanPackageItemRemove="onPlanPackageItemRemove"
        ></ServiceSectionFormPlansVue>
      </v-card>
    </template>
  </AView>
</template>
    
<script>
import { mapGetters, mapState } from "vuex";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceSectionFormPlansVue from "../../../../../../../components/wad/organisms/services/editorForms/ServiceSectionFormPlans.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceSectionFormPlansVue,
  },
  data() {
    return {
      service: {},
    };
  },
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });
    this.service = this.serviceById(this.value.relation.params.serviceId);
  },

  computed: {
    ...mapState("ServiceStore", ["activeServiceId", "displayedServices"]),
    ...mapGetters("ServiceStore", ["serviceById"]),
  },

  methods: {
    onNavigate() {
      this.$store.dispatch("ServiceStore/setActiveSection", {
        serviceId: this.value.relation.params.serviceId,
        section: "deliverables",
      });
    },

    // ==============PRICING===============
    async onPlansUpdate() {
      if (this.$refs.plansForm.validate()) {
        this.$store.dispatch("ServiceStore/onPlansUpdate", {
          service: this.service,
        });
      }
    },
    async onPlanCreate() {
      this.$store.dispatch("ServiceStore/onPlanCreate", {
        service: this.service,
      });
    },
    async onPlanRemove(plan) {
      this.$store.dispatch("ServiceStore/onPlanRemove", {
        service: this.service,
        plan,
      });
    },
    async onPlanPackageItemCreate(plan, items) {
      this.$store.dispatch("ServiceStore/onPlanPackageItemCreate", {
        service: this.service,
        plan,
        items,
      });
    },
    async onPlanPackageItemRemove(plan, item) {
      this.$store.dispatch("ServiceStore/onPlanPackageItemRemove", {
        service: this.service,
        plan,
        item,
      });
    },
  },
  watch: {
    displayedServices: {
      async handler(newVal) {
        if (newVal) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
    service: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("ServiceStore/UpdateService", {
            service: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>