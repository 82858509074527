<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <FormSliderVue
      v-model="value"
      :addText="'Add New Use Plan'"
      @onAdd="onPlanCreate"
      @onRemove="onPlanRemove"
    >
      <template v-slot:form="{ item, attrs }">
        <v-row v-bind="attrs">
          <v-col class="plans-col" cols="12" lg="4">
            <HelpFormSwitchVue
              dark
              v-model="item.is_recommended"
              label="Plan Recommended"
              :helpText="'If selected this plan will be recommended by default as a Hot Offer'"
              required
            ></HelpFormSwitchVue>
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0">
                <NumberInputVue
                  dark
                  :step="5"
                  :max="100"
                  v-model="item.min_complexity_rate"
                  label="Min Complexity Rate"
                  :helpText="'This is a minimum threshold to recommend this plan (%)'"
                  required
                >
                </NumberInputVue>
              </v-col>
              <v-col class="pa-0 ma-0">
                <NumberInputVue
                  dark
                  :step="5"
                  :max="100"
                  v-model="item.max_complexity_rate"
                  label="Max Complexity Rate"
                  :helpText="'This is a maximum threshold to recommend this plan (%)'"
                  required
                >
                </NumberInputVue>
              </v-col>
            </v-row>

          

            <SelectorIconsVue
              dark
              :helpText="'Please select icon that will be associated with Use Case'"
              v-model="item.icon"
            >
            </SelectorIconsVue>
            <HelpFormInputVue
              dark
              v-model="item.name"
              label="Plan Title"
              :helpText="'In general there we use just one Letter, but it can be different.'"
              required
            ></HelpFormInputVue>

            <HelpFormInputVue
              dark
              v-model="item.description"
              label="Plan Full Name"
              :helpText="'This is a full name of the price'"
              required
            ></HelpFormInputVue>

            <HelpFormInputVue
              dark
              v-model="item.btn_text"
              label="Plan button Text"
              :helpText="'This is a name for the button on price item.'"
              required
            ></HelpFormInputVue>
            <NumberInputVue
              dark
              :step="1"
              :max="100"
              v-model="item.duration"
              :valeFormatter="(val)=>`${val} week(s)`"
              label="Service Execution Duration"
              :helpText="'Please specify execution Duration In weeks'"
              required
            >
            </NumberInputVue>
            <NumberInputVue
              dark
              :step="1000"
              :valeFormatter="priceFormatter"
              v-model="item.price"
              label="Plan Price"
              :helpText="'This is a price for the plan. Please not that the price is in cents'"
              required
            >
            </NumberInputVue>
          </v-col>
          <v-col class="plans-col" cols="12" lg="8">
            <HelpFormInputVue
              dark
              textarea
              :rows="5"
              v-model="item.disclaimer"
              label="Plan Disclaimer"
              :helpText="'This is a disclaimer. It should say to customer how this plan fits their project'"
              required
            ></HelpFormInputVue>

            <FormSection
              underline
              :label="'Deliverables & Package Items'"
              :icon="'mdi-file-document-multiple-outline'"
              :right="true"
              :tag="'h3'"
              :actions="actions"
              :editable="!!actions.length"
              :payload="payload"
            ></FormSection>

            <SelectorDeliverablesVue
              dark
              autoload
              multiple
              v-model="newDeliverables"
              :items="deliveryTypes"
              :label="'Select Deliverable and click on +'"
              :helpText="'This is a deliverable that are included into the plan'"
            >
            </SelectorDeliverablesVue>

            <FormSliderVue
              v-model="item.packageItems"
              :itemName="'deliverable.name'"
              :addText="'Please select deliverables to add above and then click on this button.'"
              :addDisabled="!(newDeliverables && newDeliverables.length)"
              @onAdd="onPlanPackageItemCreate(item)"
              @onRemove="(rm) => onPlanPackageItemRemove(item, rm)"
            >
              <template v-slot:form="packageItemsSlider">
                <v-row v-if="packageItemsSlider.item.deliverable">
                  <v-col cols="12">
                    <v-row>
                      <v-col class="plans-col" cols="6">
                        <SelectorDefaultVue
                          dark
                          autoload
                          v-model="packageItemsSlider.item.allocation_type"
                          :items="deliveryTypes"
                          :label="'Delivery Type'"
                          :helpText="'This is a set of the deliverables that are included into the plan'"
                        >
                        </SelectorDefaultVue>
                      </v-col>
                      <v-col class="plans-col" cols="6">
                        <SelectorDefaultVue
                          dark
                          autoload
                          :items="executionMeasures"
                          v-model="packageItemsSlider.item.value_measure"
                          :label="'Delivery Measure.'"
                          :helpText="'This is a set of the deliverables that are included into the plan'"
                        >
                        </SelectorDefaultVue>
                        <NumberInputVue
                          dark
                          :step="
                            packageItemsSlider.item.value_measure === 'minutes'
                              ? 30
                              : 1
                          "
                          :valeFormatter="
                            packageItemsSlider.item.value_measure === 'minutes'
                              ? (val) => timeFormatter(val, 'm')
                              : undefined
                          "
                          v-model="packageItemsSlider.item.value"
                          label="Plan Price"
                          :helpText="'This is a price for the plan. Please not that the price is in cents'"
                          required
                        >
                        </NumberInputVue>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </FormSliderVue>
          </v-col>
        </v-row>
      </template>
    </FormSliderVue>
  </v-form>
</template>


<script>
import { FormatterHelper } from "../../../../helpers/formatter.helper";
import SelectorDefaultVue from "../../../atoms/common/autocompletes/SelectorDefault.vue";
import SelectorDeliverablesVue from "../../../atoms/common/autocompletes/SelectorDeliverables.vue";
import SelectorIconsVue from "../../../atoms/common/autocompletes/SelectorIcons.vue";
import FormSection from "../../../atoms/common/FormSection.vue";
import FormSliderVue from "../../../atoms/common/FormSlider.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";
import HelpFormSwitchVue from "../../../atoms/common/inputs/HelpFormSwitch.vue";
import NumberInputVue from "../../../atoms/common/NumberInput.vue";
import { DELIVERABLE_BUTTONS } from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: true,
      form: this.value,

      // common
      newDeliverables: undefined,
      timeFormatter: FormatterHelper.timeDuration,
      priceFormatter: FormatterHelper.price,

      // ======rules
      nameRules: [(v) => !!v || "Section Name is required"],
      deliveryTypes: [
        {
          value: "WEEKLY",
          name: "Weekly",
          description:
            "This is means that deliverable should be delivered each WEEK during service execution",
        },
        {
          value: "MONTHLY",
          name: "Monthly",
          description:
            "This is means that deliverable should be delivered each MONTH during service execution",
        },
        {
          value: "DEFAULT",
          name: "Per Assignment",
          description:
            "This is means that deliverable should be delivered ONCE during service execution",
        },
      ],

      executionMeasures: [
        {
          value: "minutes",
          name: "Minutes",
          description: "This deliverable will be delivered in hours.",
        },
        {
          value: "quantity",
          name: "Quantity",
          description: "This deliverable will be delivered as 1 package item. ",
        },
      ],

      serviceLevelRules: [(v) => !!v || "Service Level is required"],
      serviceNameRules: [(v) => !!v || "Service Name is required"],
      serviceCodeRules: [(v) => !!v || "Service Name is required"],
      serviceBtnRules: [(v) => !!v || "Action Button Text is required"],
      serviceDescriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.CREATE_NEW, DELIVERABLE_BUTTONS.LIST],
        this.permissions
      );
    },
  },
  components: {
    NumberInputVue,
    HelpFormInputVue,
    FormSliderVue,
    SelectorIconsVue,
    HelpFormSwitchVue,
    SelectorDefaultVue,
    SelectorDeliverablesVue,
    FormSection,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onPlanCreate() {
      this.$emit("onPlanCreate");
    },
    onPlanRemove(plan) {
      this.$emit("onPlanRemove", plan);
    },
    onPlanPackageItemCreate(plan) {
      this.$emit("onPlanPackageItemCreate", plan, this.newDeliverables);

      this.newDeliverables = [];
    },
    onPlanPackageItemRemove(plan, item) {
      this.$emit("onPlanPackageItemRemove", plan, item);
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.plans-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>